import RightSidebarLayout from '@/components/layout/rightSidebarLayout'
import SplashLoader from '@/components/splashLoader'
import dynamic from 'next/dynamic'
import React from 'react'

const MyHomeLazyComponent = dynamic(
  () => import('@/components/pages-partials/homePage'),
  {
    loading: () => <SplashLoader show={true} />,
  },
)

export default function MainPage() {
  return (
    <RightSidebarLayout
      footer={true}
      sportsidebar={false}
      sidebar={false}
      title="Bitcoin Sports Betting & Casino Onchain"
      isHome={true}
    >
      <MyHomeLazyComponent />
    </RightSidebarLayout>
  )
}
